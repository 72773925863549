
import { computed, defineComponent, PropType, ref } from '@cloudfun/core'
import { v1 as uuid } from 'uuid'

export default defineComponent({
  props: {
    name: String,
    modelValue: { type: Array as PropType<any[]>, default: () => [] },
    cell: Object,
    columnCount: { type: Number, default: 0 },
    readOnly: Boolean,
    items: { type: Array as PropType<any[]>, default: () => [] }
  },
  setup (props) {
    const selectedValues = ref<any[]>([])
    const itemCount = ref<any>({ all: 0 })
    const selectedCount = ref<any>({ all: 0 })

    const itemWidthPercentage = computed(() =>
      props.columnCount > 0 ? `${100 / props.columnCount}%` : 'auto'
    )

    return {
      selectedValues,
      itemCount,
      selectedCount,
      id: uuid(),
      itemWidthPercentage
    }
  },
  methods: {
    onSelectAllButtonClick (name: string) {
      const groupItem = this.items.find((e) => e.name === name)
      let items: any = null
      if (groupItem == null) {
        items = this.items
        if (this.selectedValues.length === this.itemCount[name]) {
          this.selectedValues = [];
        } else {
          this.selectedValues = [];
          items.forEach((item) => {
            if (item.items && Array.isArray(item.items)) {
              item.items.forEach((subItems: any) => {
                if (!this.selectedValues.includes(subItems.value)) { this.selectedValues.push(subItems.value) }
              })
            } else if (!this.selectedValues.includes(item.value)) { this.selectedValues.push(item.value) }
          })
        }
      } else {
        items = groupItem.items
        var allSelected = true
        items.forEach((item: any) => {
          if(allSelected) {
            this.selectedValues.includes(item.value);
          }
          // allSelected &&= this.selectedValues.includes(item.value)
        })
        if (allSelected) {
          var values = groupItem.items.map((e: any) => e.value)
          this.selectedValues = this.selectedValues.filter(
            (e) => !values.includes(e)
          )
        } else {
          items.forEach((item: any) => {
            if (!this.selectedValues.includes(item.value)) { this.selectedValues.push(item.value) }
          })
        }
      }
      this.onChange()
    },
    onChange (e?: Event, silent?: boolean) {
      this.selectedCount = { all: this.selectedValues.length }
      this.selectedValues.forEach(value => {
        this.items.forEach(item => {
          if (item.items) {
            const group = item
            if (group.items.find((e: any) => e.value === value)) {
              this.selectedCount[group.name] = (this.selectedCount[group.name] || 0) + 1
            }
          }
        })
      })
      if (!silent) {
        this.$emit('update:modelValue', this.selectedValues)
        this.$emit('change', this.selectedValues)
        if (this.cell) this.cell.setValue(this.selectedValues)
      }
    }
  },
  created () {
    this.items.forEach(item => {
      if (item.items && Array.isArray(item.items)) {
        const group = item
        this.itemCount[group.name] = group.items.length
        this.itemCount.all += group.items.length
      } else this.itemCount.all++
    })
    this.selectedValues = [...this.modelValue]
    this.onChange(undefined, true)
  },
  watch: {
    items () {
      this.itemCount.all = 0
      this.items.forEach((item) => {
        if (item.items && Array.isArray(item.items)) {
          this.itemCount[item.name] = item.items.length
          this.itemCount.all += item.items.length
        } else this.itemCount.all++
      })
      this.onChange(undefined, true)
    },
    modelValue (current) {
      this.selectedValues = [...current]
      this.onChange(undefined, true)
    }
  }
})
